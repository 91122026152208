import React from 'react'
import { graphql } from 'gatsby'
// import AniLink from 'gatsby-plugin-transition-link/AniLink'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import Card from '../components/Card'

// import CustomCategoryFilterEn from '../components/CustomCategoryFilterEn'
// import SingleContentOnProjects from '../components/SingleContentOnProjects'
// import posed, { PoseGroup } from 'react-pose'
import FullWidthProjectSr from '../components/FullWidthProjectComponentSr'
import NavbarSr from '../components/NavbarSr'
import FooterSr from '../components/FooterSr'

import Favicon from '../favicon-32x32.png'
import ogMetaSr from '../img/an_og_projects_sr.jpg'

// const CatWrap = posed.div({
//   enter: {
//     y: 0,
//     opacity: 1,
//     delay: 300,
//     transition: {
//       y: { type: 'spring', stiffness: 100, damping: 100 },
//       default: { duration: 300 },
//     },
//   },
//   exit: {
//     y: 50,
//     opacity: 0,
//     transition: { duration: 150 },
//   },
// })

const LocationAll = ({ allProjects }) => (
  <>
    {allProjects.map(({ node }) => (
      <div
        key={node.id}
        className="preanimate actv elementOfGallery animated fadeIn  delay-1s "
      >
        <div className="atviated leChild">
          <FullWidthProjectSr
            title={node.title}
            content={node.content}
            oneLiner={node.projectOneliner.projectoneliner}
            image={node.featuredImage.node.localFile.childImageSharp.original.src}
            url={node.uri}
          />
        </div>
      </div>
    ))}
  </>
)

const LocationEight = ({ cleanEight }) => (
  <div>
    {cleanEight.map((element, key) => (
      <div
        key={element.id}
        className="preanimate actv elementOfGallery animated fadeIn  delay-1s "
      >
        <div className="atviated leChild">
          <FullWidthProjectSr
            title={element.title}
            content={element.content}
            oneLiner={element.projectOneliner.projectoneliner}
            image={element.featuredImage.node.localFile.childImageSharp.original.src}
            url={element.uri}
          />
        </div>
      </div>
    ))}
  </div>
)

const LocationNine = ({ cleanNine }) => (
  <div>
    {cleanNine.map((element, key) => (
      <div
        key={element.id}
        className="preanimate actv elementOfGallery animated fadeIn  delay-1s "
      >
        <div className="atviated leChild">
          <FullWidthProjectSr
            title={element.title}
            content={element.content}
            oneLiner={element.projectOneliner.projectoneliner}
            image={element.featuredImage.node.localFile.childImageSharp.original.src}
            url={element.uri}
          />
        </div>
      </div>
    ))}
  </div>
)

class ProjectsPageSr extends React.Component {
  constructor() {
    super()
    this.state = {
      activeLocation: 'all',
      isVisible: false,
    }
    this.showLocationAll = this.showLocationAll.bind(this)
    this.showLocationEight = this.showLocationEight.bind(this)
    this.showLocationNine = this.showLocationNine.bind(this)
  }

  showLocationAll() {
    this.setState({
      activeLocation: 'all',
    })
  }

  showLocationEight() {
    this.setState({
      activeLocation: 'eight',
    })
  }

  showLocationNine() {
    this.setState({
      activeLocation: 'nine',
    })
  }

  handleChange = (categoryId) => {
    this.setState({ leActiveCategory: categoryId })
  }

  render() {
    const { data } = this.props
    const { edges: allProjects } = data.allWpProjectPostType
    const onlyEight = allProjects.map(({ node }) => {
      if (node.categories.nodes[0].termTaxonomyId === 4) return node
    }, null)

    const onlyNine = allProjects.map(({ node }) => {
      if (node.categories.nodes[0].termTaxonomyId === 5) return node
    }, null)

    const cleanEight = onlyEight.filter(
      (elementEight) => elementEight !== undefined
    )

    const cleanNine = onlyNine.filter(
      (elementNine) => elementNine !== undefined
    )
    // console.log(cleanEight)
    // console.log('cleanEight')
    // console.log(cleanNine)
    // console.log('cleanNine')

    // const { leActiveCategory } = this.state
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Projekti | Interior Design Studio</title>
          <link rel="canonical" href="http://ananenadovic.com/sr/projects" />

          <meta name="description" content="Naši projekti" />
          <meta name="robots" content="index" />
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
          <meta
            property="og:title"
            content="Projects | Interior Design Studio"
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://ananenadovic.com/sr/projects/"
          />
          <meta property="og:image" content={ogMetaSr} />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="Projekti | Interior Design Studio"
          />
          <meta name="twitter:description" content="Naši projekti" />
          <meta name="twitter:image" content={ogMetaSr} />
        </Helmet>
        <NavbarSr />
        <div className="test">
          <div className="allProjectsWrap">
            <div
              id="btn-group-iso"
              className="button-group filters-button-group"
            >
              <div className="category-holder">
                <h1>Projekti</h1>
                <div
                  className={
                    this.state.activeLocation === 'all'
                      ? 'is_active catButton  active '
                      : ' catButton '
                  }
                  role="button"
                  onClick={this.showLocationAll}
                >
                  Svi
                </div>

                <div
                  className={
                    this.state.activeLocation === 'eight'
                      ? 'is_active catButton  active '
                      : ' catButton '
                  }
                  onClick={this.showLocationEight}
                >
                  Komercijalni
                </div>

                <div
                  className={
                    this.state.activeLocation === 'nine'
                      ? 'is_active catButton  active '
                      : ' catButton '
                  }
                  onClick={this.showLocationNine}
                >
                  Stambeni
                </div>
              </div>
              <div className="section gallerySingleSection">
                <div className=" gallerySingleGrid">
                  {(() => {
                    if (this.state.activeLocation === 'all') {
                      return <LocationAll allProjects={allProjects} />
                    }
                    if (this.state.activeLocation === 'eight') {
                      return <LocationEight cleanEight={cleanEight} />
                    }
                    if (this.state.activeLocation === 'nine') {
                      return <LocationNine cleanNine={cleanNine} />
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
          <div className="stickTheFooter">
            <FooterSr />
          </div>
        </div>
      </div>
    )
  }
}

ProjectsPageSr.propTypes = {
  data: PropTypes.shape({
    allWpProjectPostType: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  cleanFour: PropTypes.array,
}

export default ProjectsPageSr

export const pageQuery = graphql`
  query ProjectsByIdSr {
    allWpProjectPostType(
      sort: { fields: date, order: DESC }
      filter: { locale: { locale: { eq: "sr_RS" } } }
    ) {
      edges {
        node {
          id
          title
          slug
          content
          uri
          categories {
            nodes {
              slug
              name
              count
              id
              termTaxonomyId
            }
          }
          projectOneliner {
            projectdatetext
            projectoneliner
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaItemUrl
              slug
              sourceUrl
              mediaDetails {
                file
                width
                height
              }
            }
          }
        }
      }
    }
  }
`
