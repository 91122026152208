import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

class FullWidthProjectSr extends Component {
  render() {
    const { title,  oneLiner, image, url } = this.props

    return (
      <>
        <Fade>
          <div
            className="img-half"
            style={{ backgroundImage: `url(${image})` }}
          >
            <div className="content-container">
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="text-overlay-half">
            <div className="content-container">
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </div>

          <div className="content-half">
            <div className="content-container">
              <div className="copy">
                <div dangerouslySetInnerHTML={{ __html: oneLiner }} />
                <div>
                  <AniLink className="catButton" fade to={url}>
                    POGLEDAJ VIŠE
                  </AniLink>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </>
    )
  }
}

FullWidthProjectSr.propTypes = {
  content: PropTypes.string,
  oneLiner: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
}
export default FullWidthProjectSr
